// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactCard_user_card__3WOQ8 {
    box-shadow: rgba(0, 0, 0, 0.15) 0 0 40px;
    border-collapse: separate;
    border-radius: 20px;
    border-spacing: 13px 6px;
    padding: 1em;
    margin-bottom: 2%;
    margin-right: 15px;
}

table.ContactCard_user__4Z1Um {
    font-size: 18px;
    border-collapse: separate;
    border-spacing: 1em 0.1em;
}

.ContactCard_title_table__ChBc9 {
    font-weight: 500;
    opacity: 0.5;
}

.ContactCard_data_table__vIXos {
    font-weight: 600;
}

td {
    vertical-align: top;
    font-size: 18px;
}

.ContactCard_social_networks_container__rI3cW {
    margin-top: 20px;
    display: flex;
    padding-left: 15px;
}

@media screen and (max-width: 575.5px) {
    .ContactCard_user_data__dZO3N {
        overflow: auto;
        width: 310px;
    }

    .ContactCard_user_card__3WOQ8 {
        margin-right: 0;
    }
}

@media screen and (max-width: 349px) {
    td {
        font-size: 0.77em
    }

    .ContactCard_user_data__dZO3N {
        width: 230px;
    }

    .ContactCard_user_card__3WOQ8 {
        padding: 0.6em;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Contacts/ContactCard/ContactCard.module.css"],"names":[],"mappings":"AAAA;IACI,wCAAwC;IACxC,yBAAyB;IACzB,mBAAmB;IACnB,wBAAwB;IACxB,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,yBAAyB;IACzB,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI;QACI,cAAc;QACd,YAAY;IAChB;;IAEA;QACI,eAAe;IACnB;AACJ;;AAEA;IACI;QACI;IACJ;;IAEA;QACI,YAAY;IAChB;;IAEA;QACI,cAAc;IAClB;AACJ","sourcesContent":[".user_card {\n    box-shadow: rgba(0, 0, 0, 0.15) 0 0 40px;\n    border-collapse: separate;\n    border-radius: 20px;\n    border-spacing: 13px 6px;\n    padding: 1em;\n    margin-bottom: 2%;\n    margin-right: 15px;\n}\n\ntable.user {\n    font-size: 18px;\n    border-collapse: separate;\n    border-spacing: 1em 0.1em;\n}\n\n.title_table {\n    font-weight: 500;\n    opacity: 0.5;\n}\n\n.data_table {\n    font-weight: 600;\n}\n\ntd {\n    vertical-align: top;\n    font-size: 18px;\n}\n\n.social_networks_container {\n    margin-top: 20px;\n    display: flex;\n    padding-left: 15px;\n}\n\n@media screen and (max-width: 575.5px) {\n    .user_data {\n        overflow: auto;\n        width: 310px;\n    }\n\n    .user_card {\n        margin-right: 0;\n    }\n}\n\n@media screen and (max-width: 349px) {\n    td {\n        font-size: 0.77em\n    }\n\n    .user_data {\n        width: 230px;\n    }\n\n    .user_card {\n        padding: 0.6em;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"user_card": `ContactCard_user_card__3WOQ8`,
	"user": `ContactCard_user__4Z1Um`,
	"title_table": `ContactCard_title_table__ChBc9`,
	"data_table": `ContactCard_data_table__vIXos`,
	"social_networks_container": `ContactCard_social_networks_container__rI3cW`,
	"user_data": `ContactCard_user_data__dZO3N`
};
export default ___CSS_LOADER_EXPORT___;
