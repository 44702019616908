// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Login_offer__dYQh7 {
    justify-content: center;
    display: flex;
    width: 100%;
    left: 0;
    top: 0;
    position: absolute;
    height: 100%;
    align-content: center;
    align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Login/Login.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,aAAa;IACb,WAAW;IACX,OAAO;IACP,MAAM;IACN,kBAAkB;IAClB,YAAY;IACZ,qBAAqB;IACrB,mBAAmB;AACvB","sourcesContent":[".offer {\n    justify-content: center;\n    display: flex;\n    width: 100%;\n    left: 0;\n    top: 0;\n    position: absolute;\n    height: 100%;\n    align-content: center;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"offer": `Login_offer__dYQh7`
};
export default ___CSS_LOADER_EXPORT___;
