// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditProfile_gray_background__vjGsl {
    position: absolute;
    width: 100%;
    min-height: 100vh;
    color: black;
    background-color: #eaeaea;
}

.EditProfile_header__Gfqa5 {
    margin-top: 25px;
    margin-bottom: 2%;
}`, "",{"version":3,"sources":["webpack://./src/pages/EditProfile/EditProfile.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,iBAAiB;IACjB,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[".gray_background {\n    position: absolute;\n    width: 100%;\n    min-height: 100vh;\n    color: black;\n    background-color: #eaeaea;\n}\n\n.header {\n    margin-top: 25px;\n    margin-bottom: 2%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gray_background": `EditProfile_gray_background__vjGsl`,
	"header": `EditProfile_header__Gfqa5`
};
export default ___CSS_LOADER_EXPORT___;
