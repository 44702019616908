// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PetCreation_orange_background__lS5aw {
    position: absolute;
    width: 100%;
    min-height: 100vh;
    color: black;
    background-color: #ff742b4f;
}

.PetCreation_header__Rmw0U {
    margin-top: 25px;
    margin-bottom: 2%;
}


`, "",{"version":3,"sources":["webpack://./src/pages/PetCreation/PetCreation.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,iBAAiB;IACjB,YAAY;IACZ,2BAA2B;AAC/B;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[".orange_background {\n    position: absolute;\n    width: 100%;\n    min-height: 100vh;\n    color: black;\n    background-color: #ff742b4f;\n}\n\n.header {\n    margin-top: 25px;\n    margin-bottom: 2%;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"orange_background": `PetCreation_orange_background__lS5aw`,
	"header": `PetCreation_header__Rmw0U`
};
export default ___CSS_LOADER_EXPORT___;
