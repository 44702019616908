// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditeProfileOffer_window__g5XMu {
    width: 40%;
    margin-bottom: 5%;
}

.EditeProfileOffer_window__g5XMu h1 {
    text-align: center;
}

.EditeProfileOffer_offer__E6QXF .EditeProfileOffer_row__aNmBz {
    position: relative;
    align-items: normal;
}

.EditeProfileOffer_delete_container__ewDe4 {
    position: absolute;
    left: 63%;
    transform: translateX(50%);
}

.EditeProfileOffer_delete_container__ewDe4 img {
    height: 48px;
}

@media screen and (max-width: 991.5px){
    .EditeProfileOffer_window__g5XMu {
        width: 60%;
    }
}

@media screen and (max-width: 767.5px){
    .EditeProfileOffer_window__g5XMu {
        width: 75%;
    }
}

@media screen and (max-width: 575.5px) {
    .EditeProfileOffer_window__g5XMu {
        width: 80%;
    }
}

@media screen and (max-width: 493px) {
    .EditeProfileOffer_window__g5XMu h1 {
        font-size: 20px;
    }

    .EditeProfileOffer_window__g5XMu {
        width: 95%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/EditProfileOffer/EditeProfileOffer.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,0BAA0B;AAC9B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI;QACI,UAAU;IACd;AACJ;;AAEA;IACI;QACI,UAAU;IACd;AACJ;;AAEA;IACI;QACI,UAAU;IACd;AACJ;;AAEA;IACI;QACI,eAAe;IACnB;;IAEA;QACI,UAAU;IACd;AACJ","sourcesContent":[".window {\n    width: 40%;\n    margin-bottom: 5%;\n}\n\n.window h1 {\n    text-align: center;\n}\n\n.offer .row {\n    position: relative;\n    align-items: normal;\n}\n\n.delete_container {\n    position: absolute;\n    left: 63%;\n    transform: translateX(50%);\n}\n\n.delete_container img {\n    height: 48px;\n}\n\n@media screen and (max-width: 991.5px){\n    .window {\n        width: 60%;\n    }\n}\n\n@media screen and (max-width: 767.5px){\n    .window {\n        width: 75%;\n    }\n}\n\n@media screen and (max-width: 575.5px) {\n    .window {\n        width: 80%;\n    }\n}\n\n@media screen and (max-width: 493px) {\n    .window h1 {\n        font-size: 20px;\n    }\n\n    .window {\n        width: 95%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"window": `EditeProfileOffer_window__g5XMu`,
	"offer": `EditeProfileOffer_offer__E6QXF`,
	"row": `EditeProfileOffer_row__aNmBz`,
	"delete_container": `EditeProfileOffer_delete_container__ewDe4`
};
export default ___CSS_LOADER_EXPORT___;
