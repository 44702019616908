// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 995px) {
    .EditPetOffer_delete_link__-B1N9 {
        display: none;
    }

    .EditPetOffer_authorization_window__1rx-u {
        text-align: center;
    }

    .EditPetOffer_authorization_window__1rx-u h1 {
        font-size: 1.5rem; 
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/EditPetOffer/EditPetOffer.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,kBAAkB;IACtB;;IAEA;QACI,iBAAiB;IACrB;AACJ","sourcesContent":["@media screen and (max-width: 995px) {\n    .delete_link {\n        display: none;\n    }\n\n    .authorization_window {\n        text-align: center;\n    }\n\n    .authorization_window h1 {\n        font-size: 1.5rem; \n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"delete_link": `EditPetOffer_delete_link__-B1N9`,
	"authorization_window": `EditPetOffer_authorization_window__1rx-u`
};
export default ___CSS_LOADER_EXPORT___;
