// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginOffer_h1__PxK7x {
    font-size: 1.5rem;
    font-weight: bold;
    color: black;
}

.LoginOffer_title__zvxRG {
    margin-top: 35px;
    margin-bottom: 20px;
}

.LoginOffer_offer__68l2A {
    justify-content: center;
    display: contents;
    width: 100%;
    left: 0;
    top: 0;
    position: absolute;
    height: 100%;
    align-content: center;
    align-items: center;
}

.LoginOffer_authorization_window__WahD4 {
    width: 100%;
    border-radius: 43px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 10px;
}

.LoginOffer_elem_in_window__3c19I {
    margin: auto;
}

.LoginOffer_title__zvxRG {
    margin-top: 35px;
    margin-bottom: 20px;
}

.LoginOffer_registration_link__C8KTJ {
    font-size: 17px;
    font-weight: bold;
    color: black;
    background-color: #D9D9D9;
    border-radius: 64px;
    padding: 10px 35px;
    text-decoration: none;
}

.LoginOffer_registration_link__C8KTJ:hover {
    text-decoration: none;
    color: black;
    background-color: #D9D9D9;
    box-shadow: 1px 1px 30px 6px rgba(217, 217, 217, 80);
}
`, "",{"version":3,"sources":["webpack://./src/components/Auth/LoginOffer/LoginOffer.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,uBAAuB;IACvB,iBAAiB;IACjB,WAAW;IACX,OAAO;IACP,MAAM;IACN,kBAAkB;IAClB,YAAY;IACZ,qBAAqB;IACrB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,oBAAoB;AACxB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;IACnB,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;IACrB,YAAY;IACZ,yBAAyB;IACzB,oDAAoD;AACxD","sourcesContent":[".h1 {\n    font-size: 1.5rem;\n    font-weight: bold;\n    color: black;\n}\n\n.title {\n    margin-top: 35px;\n    margin-bottom: 20px;\n}\n\n.offer {\n    justify-content: center;\n    display: contents;\n    width: 100%;\n    left: 0;\n    top: 0;\n    position: absolute;\n    height: 100%;\n    align-content: center;\n    align-items: center;\n}\n\n.authorization_window {\n    width: 100%;\n    border-radius: 43px;\n    background-color: white;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    padding-bottom: 10px;\n}\n\n.elem_in_window {\n    margin: auto;\n}\n\n.title {\n    margin-top: 35px;\n    margin-bottom: 20px;\n}\n\n.registration_link {\n    font-size: 17px;\n    font-weight: bold;\n    color: black;\n    background-color: #D9D9D9;\n    border-radius: 64px;\n    padding: 10px 35px;\n    text-decoration: none;\n}\n\n.registration_link:hover {\n    text-decoration: none;\n    color: black;\n    background-color: #D9D9D9;\n    box-shadow: 1px 1px 30px 6px rgba(217, 217, 217, 80);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"h1": `LoginOffer_h1__PxK7x`,
	"title": `LoginOffer_title__zvxRG`,
	"offer": `LoginOffer_offer__68l2A`,
	"authorization_window": `LoginOffer_authorization_window__WahD4`,
	"elem_in_window": `LoginOffer_elem_in_window__3c19I`,
	"registration_link": `LoginOffer_registration_link__C8KTJ`
};
export default ___CSS_LOADER_EXPORT___;
