// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Contacts_contacts_header__8Qic4 {
    margin-bottom: 0.8em;
}

.Contacts_contacts_header__8Qic4 h2 {
    font-weight: bold;
    font-size: 1.6em;
}

@media screen and (max-width: 767.5px) {
    .Contacts_contacts_header__8Qic4 h2 {
        font-size: 1.4em;
    }
}

@media screen and (max-width: 575.5px) {
    .Contacts_contacts_header__8Qic4 h2 {
        text-align: center;
    }

    .Contacts_contacts_cards__Zlprt {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-right: 0;
        margin-left: 0;
    }
}

@media screen and (max-width: 349px) {
    .Contacts_contacts_header__8Qic4 h2 {
        font-size: 1.15em;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/Contacts/Contacts.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;AACxB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI;QACI,gBAAgB;IACpB;AACJ;;AAEA;IACI;QACI,kBAAkB;IACtB;;IAEA;QACI,WAAW;QACX,aAAa;QACb,uBAAuB;QACvB,eAAe;QACf,cAAc;IAClB;AACJ;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".contacts_header {\n    margin-bottom: 0.8em;\n}\n\n.contacts_header h2 {\n    font-weight: bold;\n    font-size: 1.6em;\n}\n\n@media screen and (max-width: 767.5px) {\n    .contacts_header h2 {\n        font-size: 1.4em;\n    }\n}\n\n@media screen and (max-width: 575.5px) {\n    .contacts_header h2 {\n        text-align: center;\n    }\n\n    .contacts_cards {\n        width: 100%;\n        display: flex;\n        justify-content: center;\n        margin-right: 0;\n        margin-left: 0;\n    }\n}\n\n@media screen and (max-width: 349px) {\n    .contacts_header h2 {\n        font-size: 1.15em;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contacts_header": `Contacts_contacts_header__8Qic4`,
	"contacts_cards": `Contacts_contacts_cards__Zlprt`
};
export default ___CSS_LOADER_EXPORT___;
