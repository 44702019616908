// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PetFeatures_features_header__v83kk h2 {
    font-weight: bold;
    font-size: 1.6em;
}

.PetFeatures_features_orange__wCT5B {
    color: rgb(255, 116, 43);
}

.PetFeatures_features_list__vOPqz {
    align-items: normal;
}

@media screen and (max-width: 767.5px) {
    .PetFeatures_features_header__v83kk h2 {
        font-size: 1.4em;
    }
}

@media screen and (max-width: 575.5px) {
    .PetFeatures_display_none_mobile__3jac7 {
        display: none;
    }

    .PetFeatures_features_header__v83kk h2 {
        text-align: center;
        font-size: 1.4em;
    }

    .PetFeatures_features_list__vOPqz {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-right: 0px;
        margin-left: 0px;
    }
}

@media screen and (max-width: 349px) {
    .PetFeatures_features_header__v83kk h2 {
        font-size: 1.15em;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/PetFeatures/PetFeatures.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI;QACI,gBAAgB;IACpB;AACJ;;AAEA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,kBAAkB;QAClB,gBAAgB;IACpB;;IAEA;QACI,WAAW;QACX,aAAa;QACb,uBAAuB;QACvB,iBAAiB;QACjB,gBAAgB;IACpB;AACJ;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".features_header h2 {\n    font-weight: bold;\n    font-size: 1.6em;\n}\n\n.features_orange {\n    color: rgb(255, 116, 43);\n}\n\n.features_list {\n    align-items: normal;\n}\n\n@media screen and (max-width: 767.5px) {\n    .features_header h2 {\n        font-size: 1.4em;\n    }\n}\n\n@media screen and (max-width: 575.5px) {\n    .display_none_mobile {\n        display: none;\n    }\n\n    .features_header h2 {\n        text-align: center;\n        font-size: 1.4em;\n    }\n\n    .features_list {\n        width: 100%;\n        display: flex;\n        justify-content: center;\n        margin-right: 0px;\n        margin-left: 0px;\n    }\n}\n\n@media screen and (max-width: 349px) {\n    .features_header h2 {\n        font-size: 1.15em;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"features_header": `PetFeatures_features_header__v83kk`,
	"features_orange": `PetFeatures_features_orange__wCT5B`,
	"features_list": `PetFeatures_features_list__vOPqz`,
	"display_none_mobile": `PetFeatures_display_none_mobile__3jac7`
};
export default ___CSS_LOADER_EXPORT___;
