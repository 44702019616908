// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PetCard_wrapper__7zwMd {
    margin-bottom: 10%;
    max-width: none;
    width: 300px;
    height: 200px;
    border-radius: 20px;
}

.PetCard_pet_card__ck-r- {
    position: relative;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
}

.PetCard_pet_card__ck-r-:hover {
    background: linear-gradient(to top, rgb(255 88 0), rgba(255, 116, 43, 0.8)) !important;
    background-size: cover !important;
}

.PetCard_pet_name__UwY-\\+ {
    position: absolute;
    bottom: 0;
    margin-left: 5%;
    color: white;
}

.PetCard_pet_name__UwY-\\+ h5 {
    font-weight: bold;
    font-size: 27px;
}

@media screen and (min-width: 767.6px) and (max-width: 768.5px) {
    .PetCard_wrapper__7zwMd {
        grid-template-columns: repeat(auto-fit, minmax(50%, 1fr)); 
        gap: 10px; 
    }

    .PetCard_pet_card__ck-r- {
        width: auto; 
    }
}

@media screen and (max-width: 767.5px) {
    .PetCard_md__wpkv7 {
        padding-left: 0px;
    }

    .PetCard_pet_card__ck-r- {
        width: 100%;
    }
}

@media screen and (max-width: 575.5px) {
    .PetCard_col11__4950C {
        margin-left: auto;
        margin-right: auto;
        padding-right: 0;
    }

    .PetCard_pet_card__ck-r- {
        margin-right: auto;
        margin-left: auto;
        width: 90%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/PetCard/PetCard.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,uCAAuC;IACvC,sCAAsC;IACtC,iCAAiC;AACrC;;AAEA;IACI,sFAAsF;IACtF,iCAAiC;AACrC;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI;QACI,yDAAyD;QACzD,SAAS;IACb;;IAEA;QACI,WAAW;IACf;AACJ;;AAEA;IACI;QACI,iBAAiB;IACrB;;IAEA;QACI,WAAW;IACf;AACJ;;AAEA;IACI;QACI,iBAAiB;QACjB,kBAAkB;QAClB,gBAAgB;IACpB;;IAEA;QACI,kBAAkB;QAClB,iBAAiB;QACjB,UAAU;IACd;AACJ","sourcesContent":[".wrapper {\n    margin-bottom: 10%;\n    max-width: none;\n    width: 300px;\n    height: 200px;\n    border-radius: 20px;\n}\n\n.pet_card {\n    position: relative;\n    background-repeat: no-repeat !important;\n    background-position: center !important;\n    background-size: cover !important;\n}\n\n.pet_card:hover {\n    background: linear-gradient(to top, rgb(255 88 0), rgba(255, 116, 43, 0.8)) !important;\n    background-size: cover !important;\n}\n\n.pet_name {\n    position: absolute;\n    bottom: 0;\n    margin-left: 5%;\n    color: white;\n}\n\n.pet_name h5 {\n    font-weight: bold;\n    font-size: 27px;\n}\n\n@media screen and (min-width: 767.6px) and (max-width: 768.5px) {\n    .wrapper {\n        grid-template-columns: repeat(auto-fit, minmax(50%, 1fr)); \n        gap: 10px; \n    }\n\n    .pet_card {\n        width: auto; \n    }\n}\n\n@media screen and (max-width: 767.5px) {\n    .md {\n        padding-left: 0px;\n    }\n\n    .pet_card {\n        width: 100%;\n    }\n}\n\n@media screen and (max-width: 575.5px) {\n    .col11 {\n        margin-left: auto;\n        margin-right: auto;\n        padding-right: 0;\n    }\n\n    .pet_card {\n        margin-right: auto;\n        margin-left: auto;\n        width: 90%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `PetCard_wrapper__7zwMd`,
	"pet_card": `PetCard_pet_card__ck-r-`,
	"pet_name": `PetCard_pet_name__UwY-+`,
	"md": `PetCard_md__wpkv7`,
	"col11": `PetCard_col11__4950C`
};
export default ___CSS_LOADER_EXPORT___;
