// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Feature_feature__dwAwr {
    margin-top: 0.5em;
}

.Feature_feature_description__W2dWA {
    font-weight: 600;
    max-width: 28em;
}

.Feature_feature__dwAwr h3 {
    font-weight: 600;
    color: #FF742B;
}

.Feature_feature__dwAwr p {
    font-weight: 500;
}

@media screen and (max-width: 991.5px) {
    .Feature_feature_description__W2dWA {
        font-size: 0.9em;
    }

    .Feature_feature__dwAwr h3 {
        font-size: 1.3em;
    }
}

@media screen and (max-width: 767.5px) {
    .Feature_feature_description__W2dWA {
        max-width: 15em;
    }
}

@media screen and (max-width: 575.5px) {
    .Feature_feature_description__W2dWA {
        text-align: center;
        font-size: 1.1em;
        max-width: none;
    }

    .Feature_feature__dwAwr h3 {
        text-align: center;
        font-size: 1.5em;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/PetFeatures/Feature/Feature.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI;QACI,gBAAgB;IACpB;;IAEA;QACI,gBAAgB;IACpB;AACJ;;AAEA;IACI;QACI,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,kBAAkB;QAClB,gBAAgB;QAChB,eAAe;IACnB;;IAEA;QACI,kBAAkB;QAClB,gBAAgB;IACpB;AACJ","sourcesContent":[".feature {\n    margin-top: 0.5em;\n}\n\n.feature_description {\n    font-weight: 600;\n    max-width: 28em;\n}\n\n.feature h3 {\n    font-weight: 600;\n    color: #FF742B;\n}\n\n.feature p {\n    font-weight: 500;\n}\n\n@media screen and (max-width: 991.5px) {\n    .feature_description {\n        font-size: 0.9em;\n    }\n\n    .feature h3 {\n        font-size: 1.3em;\n    }\n}\n\n@media screen and (max-width: 767.5px) {\n    .feature_description {\n        max-width: 15em;\n    }\n}\n\n@media screen and (max-width: 575.5px) {\n    .feature_description {\n        text-align: center;\n        font-size: 1.1em;\n        max-width: none;\n    }\n\n    .feature h3 {\n        text-align: center;\n        font-size: 1.5em;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"feature": `Feature_feature__dwAwr`,
	"feature_description": `Feature_feature_description__W2dWA`
};
export default ___CSS_LOADER_EXPORT___;
