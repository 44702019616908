// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginForm_form_container__fJQvN {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
}

.LoginForm_elem_in_window__ABh9H {
    margin: auto;
}

.LoginForm_form__yx7Sp {
    text-align: center;
}

.LoginForm_input__JZ7wz {
    width: 80%;
    padding: 10px 30px;
    border-style: solid;
    font-size: 15px;
    font-weight: 600;
    color: black;
    border-width: 3px;
    border-color: #FF742B;
    border-radius: 20px;
    outline: none;
}

.LoginForm_button__jf\\+Ww {
    display: block;
    font-weight: bold;
    border-radius: 64px;
    padding: 10px 35px;
    font-size: 17px;
    border-style: none;
    outline: none;
    background-color: #FF742B;
    color: white;
    cursor: pointer;
    transition: 0.2s;
}

.LoginForm_button__jf\\+Ww:hover {
    background-color: #FF742B;
    box-shadow: 1px 1px 30px 6px rgba(255, 116, 43, 100);
}

@media screen and (min-width: 992px){
    .LoginForm_input__JZ7wz {
        width: 280px;
    }
}

@media screen and (max-width: 576px){
    .LoginForm_input__JZ7wz {
        width: 85%;
    }
}

`, "",{"version":3,"sources":["webpack://./src/components/Forms/LoginForm/LoginForm.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,iBAAiB;IACjB,qBAAqB;IACrB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,oDAAoD;AACxD;;AAEA;IACI;QACI,YAAY;IAChB;AACJ;;AAEA;IACI;QACI,UAAU;IACd;AACJ","sourcesContent":[".form_container {\n    display: flex;\n    justify-content: center;\n    margin-bottom: 25px;\n}\n\n.elem_in_window {\n    margin: auto;\n}\n\n.form {\n    text-align: center;\n}\n\n.input {\n    width: 80%;\n    padding: 10px 30px;\n    border-style: solid;\n    font-size: 15px;\n    font-weight: 600;\n    color: black;\n    border-width: 3px;\n    border-color: #FF742B;\n    border-radius: 20px;\n    outline: none;\n}\n\n.button {\n    display: block;\n    font-weight: bold;\n    border-radius: 64px;\n    padding: 10px 35px;\n    font-size: 17px;\n    border-style: none;\n    outline: none;\n    background-color: #FF742B;\n    color: white;\n    cursor: pointer;\n    transition: 0.2s;\n}\n\n.button:hover {\n    background-color: #FF742B;\n    box-shadow: 1px 1px 30px 6px rgba(255, 116, 43, 100);\n}\n\n@media screen and (min-width: 992px){\n    .input {\n        width: 280px;\n    }\n}\n\n@media screen and (max-width: 576px){\n    .input {\n        width: 85%;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form_container": `LoginForm_form_container__fJQvN`,
	"elem_in_window": `LoginForm_elem_in_window__ABh9H`,
	"form": `LoginForm_form__yx7Sp`,
	"input": `LoginForm_input__JZ7wz`,
	"button": `LoginForm_button__jf+Ww`
};
export default ___CSS_LOADER_EXPORT___;
