// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HomeNavbar_registration_link__aGwcq {
    margin-left: 3em;
    font-size: 16px;
    color: white;
    position: relative;
    text-decoration: none;
}

.HomeNavbar_registration_link__aGwcq:hover {
    color: white;
    text-decoration: underline;
}

.HomeNavbar_login_link__GfTBD {
    font-size: 16px;
    font-weight: 600;
    background-color: white;
    color: #462446;
    border-radius: 35px;
    padding: 1em 1.5em;
    text-decoration: none;
}

.HomeNavbar_login_link__GfTBD:hover {
    text-decoration: none;
    color: #462446;
    background-color: white;
    box-shadow: 1px 1px 15px 1px rgba(255, 255, 255, 100);
}

.HomeNavbar_personal_cabinet__link__FYVeH {
    font-size: 16px;
    font-weight: 600;
    color: white;
    position: relative;
}

.HomeNavbar_personal_cabinet__link__FYVeH:hover {
    color: #FF742B;
}

@media screen and (max-width: 575.5px) {
    .HomeNavbar_login_link__GfTBD {
        padding: 0.7em 1em;
    }

    .HomeNavbar_personal_cabinet__icon__-pcnk {
        opacity: 70%;
        height: 40px;
    }

    .HomeNavbar_container__Uqr1C {
        padding: 0 15px;
        align-items: center;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Navbars/HomeNavbar/HomeNavbar.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,YAAY;IACZ,0BAA0B;AAC9B;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,uBAAuB;IACvB,cAAc;IACd,mBAAmB;IACnB,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;IACrB,cAAc;IACd,uBAAuB;IACvB,qDAAqD;AACzD;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI;QACI,kBAAkB;IACtB;;IAEA;QACI,YAAY;QACZ,YAAY;IAChB;;IAEA;QACI,eAAe;QACf,mBAAmB;IACvB;AACJ","sourcesContent":[".registration_link {\n    margin-left: 3em;\n    font-size: 16px;\n    color: white;\n    position: relative;\n    text-decoration: none;\n}\n\n.registration_link:hover {\n    color: white;\n    text-decoration: underline;\n}\n\n.login_link {\n    font-size: 16px;\n    font-weight: 600;\n    background-color: white;\n    color: #462446;\n    border-radius: 35px;\n    padding: 1em 1.5em;\n    text-decoration: none;\n}\n\n.login_link:hover {\n    text-decoration: none;\n    color: #462446;\n    background-color: white;\n    box-shadow: 1px 1px 15px 1px rgba(255, 255, 255, 100);\n}\n\n.personal_cabinet__link {\n    font-size: 16px;\n    font-weight: 600;\n    color: white;\n    position: relative;\n}\n\n.personal_cabinet__link:hover {\n    color: #FF742B;\n}\n\n@media screen and (max-width: 575.5px) {\n    .login_link {\n        padding: 0.7em 1em;\n    }\n\n    .personal_cabinet__icon {\n        opacity: 70%;\n        height: 40px;\n    }\n\n    .container {\n        padding: 0 15px;\n        align-items: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"registration_link": `HomeNavbar_registration_link__aGwcq`,
	"login_link": `HomeNavbar_login_link__GfTBD`,
	"personal_cabinet__link": `HomeNavbar_personal_cabinet__link__FYVeH`,
	"personal_cabinet__icon": `HomeNavbar_personal_cabinet__icon__-pcnk`,
	"container": `HomeNavbar_container__Uqr1C`
};
export default ___CSS_LOADER_EXPORT___;
