// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HomeOffer_text__DjTnE {
    margin-bottom: 40px;
}

.HomeOffer_text__DjTnE h1 {
    font-weight: bold;
    margin-bottom: 4px;
}

.HomeOffer_search_container__pOniF h2 {
    font-size: 1.5rem;
    font-weight: bold;
    opacity: 50%;
    margin-bottom: 20px;
}

.HomeOffer_dog_picture__E7b3y {
    height: 460px;
}

@media screen and (min-width: 576px) {
    .HomeOffer_text_mobile__a9xZ- {
        display: none;
    }
}

@media screen and (max-width: 991.5px) {
    .HomeOffer_text__DjTnE h1 {
        font-size: 2rem;
    }

    .HomeOffer_search_container__pOniF h2 {
        font-size: 1.3rem;
    }
}


@media screen and (max-width: 768px) {
    .HomeOffer_text__DjTnE {
        margin-bottom: 25px;
    }

    .HomeOffer_text__DjTnE h1 {
        font-size: 1.6rem;
    }

    .HomeOffer_search_container__pOniF h2 {
        font-size: 1rem;
    }

    .HomeOffer_dog_picture__E7b3y {
        height: 300px;
        position: relative;
        top: -40px;
        right: -45px;
    }
}

@media screen and (max-width: 575.5px) {
    .HomeOffer_mobile_display_none__4MUT9 {
        display: none;
    }

    .HomeOffer_search_container__pOniF h2 {
        text-align: center;
        font-size: 1.2rem;
        font-weight: bold;
        opacity: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/HomeOffer/HomeOffer.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI;QACI,aAAa;IACjB;AACJ;;AAEA;IACI;QACI,eAAe;IACnB;;IAEA;QACI,iBAAiB;IACrB;AACJ;;;AAGA;IACI;QACI,mBAAmB;IACvB;;IAEA;QACI,iBAAiB;IACrB;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,aAAa;QACb,kBAAkB;QAClB,UAAU;QACV,YAAY;IAChB;AACJ;;AAEA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,kBAAkB;QAClB,iBAAiB;QACjB,iBAAiB;QACjB,aAAa;IACjB;AACJ","sourcesContent":[".text {\n    margin-bottom: 40px;\n}\n\n.text h1 {\n    font-weight: bold;\n    margin-bottom: 4px;\n}\n\n.search_container h2 {\n    font-size: 1.5rem;\n    font-weight: bold;\n    opacity: 50%;\n    margin-bottom: 20px;\n}\n\n.dog_picture {\n    height: 460px;\n}\n\n@media screen and (min-width: 576px) {\n    .text_mobile {\n        display: none;\n    }\n}\n\n@media screen and (max-width: 991.5px) {\n    .text h1 {\n        font-size: 2rem;\n    }\n\n    .search_container h2 {\n        font-size: 1.3rem;\n    }\n}\n\n\n@media screen and (max-width: 768px) {\n    .text {\n        margin-bottom: 25px;\n    }\n\n    .text h1 {\n        font-size: 1.6rem;\n    }\n\n    .search_container h2 {\n        font-size: 1rem;\n    }\n\n    .dog_picture {\n        height: 300px;\n        position: relative;\n        top: -40px;\n        right: -45px;\n    }\n}\n\n@media screen and (max-width: 575.5px) {\n    .mobile_display_none {\n        display: none;\n    }\n\n    .search_container h2 {\n        text-align: center;\n        font-size: 1.2rem;\n        font-weight: bold;\n        opacity: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `HomeOffer_text__DjTnE`,
	"search_container": `HomeOffer_search_container__pOniF`,
	"dog_picture": `HomeOffer_dog_picture__E7b3y`,
	"text_mobile": `HomeOffer_text_mobile__a9xZ-`,
	"mobile_display_none": `HomeOffer_mobile_display_none__4MUT9`
};
export default ___CSS_LOADER_EXPORT___;
