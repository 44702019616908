// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PetInfo_white_background__rwxJ- {
    position: absolute;
    width: 100%;
    min-height: 100vh;
    background-color: white;
    color: black;
}

.PetInfo_nav__uHxpm {
    margin-top: 25px;
    margin-bottom: 10px;
}

.PetInfo_features__AEHBj {
    margin-top: 2em;
}

.PetInfo_contacts__LG6al {
    margin-top: 1.6em;
    margin-bottom: 2em;
}`, "",{"version":3,"sources":["webpack://./src/pages/PetInfo/PetInfo.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,iBAAiB;IACjB,uBAAuB;IACvB,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".white_background {\n    position: absolute;\n    width: 100%;\n    min-height: 100vh;\n    background-color: white;\n    color: black;\n}\n\n.nav {\n    margin-top: 25px;\n    margin-bottom: 10px;\n}\n\n.features {\n    margin-top: 2em;\n}\n\n.contacts {\n    margin-top: 1.6em;\n    margin-bottom: 2em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white_background": `PetInfo_white_background__rwxJ-`,
	"nav": `PetInfo_nav__uHxpm`,
	"features": `PetInfo_features__AEHBj`,
	"contacts": `PetInfo_contacts__LG6al`
};
export default ___CSS_LOADER_EXPORT___;
