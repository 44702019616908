// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RecaptchaWarning_container__D5DPM {
    padding: 5px 15px;
    margin-top: 25px;
    margin-bottom: 10px;
}

.RecaptchaWarning_container__D5DPM p {
    color: #ffffff47;
    text-align: center;
}

.RecaptchaWarning_container__D5DPM a {
    color: #ffffff91;
}`, "",{"version":3,"sources":["webpack://./src/components/RecaptchaWarning/RecaptchaWarning.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".container {\n    padding: 5px 15px;\n    margin-top: 25px;\n    margin-bottom: 10px;\n}\n\n.container p {\n    color: #ffffff47;\n    text-align: center;\n}\n\n.container a {\n    color: #ffffff91;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `RecaptchaWarning_container__D5DPM`
};
export default ___CSS_LOADER_EXPORT___;
