// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PetInfoNavbar_pet_info_label__lP0yS {
    font-weight: 500;
    font-size: 20px;
    opacity: 40%;
    margin-top: 15px;
    margin-left: 8px;
}

@media screen and (max-width: 1199px) {
    .PetInfoNavbar_personal_cabinet_col__ZC3SF {
        margin-left: 10px;
    }
}

@media screen and (max-width: 768px) {
    .PetInfoNavbar_personal_cabinet_col__ZC3SF {
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Navbars/PetInfoNavbar/PetInfoNavbar.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,eAAe;IACf,YAAY;IACZ,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".pet_info_label {\n    font-weight: 500;\n    font-size: 20px;\n    opacity: 40%;\n    margin-top: 15px;\n    margin-left: 8px;\n}\n\n@media screen and (max-width: 1199px) {\n    .personal_cabinet_col {\n        margin-left: 10px;\n    }\n}\n\n@media screen and (max-width: 768px) {\n    .personal_cabinet_col {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pet_info_label": `PetInfoNavbar_pet_info_label__lP0yS`,
	"personal_cabinet_col": `PetInfoNavbar_personal_cabinet_col__ZC3SF`
};
export default ___CSS_LOADER_EXPORT___;
