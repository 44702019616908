// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditProfileForm_input__RkHLa {
    background-color: #eaeaeab3;
    width: 100%;
    padding: 10px 15px;
    border-style: solid;
    font-size: 15px;
    font-weight: 500;
    color: black;
    border-width: 3px;
    border-color: #c5c5c5;
    border-radius: 20px;
    outline: none;
    margin-bottom: 10px;
}

.EditProfileForm_social_network__89OXj {
    margin-top: 20px;
}

.EditProfileForm_lock_icon__klV7w {
    margin-bottom: 4px;
    margin-left: 6px;
    height: 13px;
    opacity: 80%;
}

.EditProfileForm_social_networks_container__kESpA .EditProfileForm_input__RkHLa {
    width: 79%;
}

.EditProfileForm_button__GrcXr {
    margin-top: 15px;
}

.EditProfileForm_delete_button__fNcRa {
    display: none;
    width: 300px;
    display: block;
    font-weight: bold;
    border-radius: 64px;
    padding: 10px 35px;
    font-size: 17px;
    border: 2px solid #050101;
    background-color: white;
    color: #050101;
    cursor: pointer;
    transition: background-color 0.2s, color 0.2s;
    outline: none;
    margin-top: 20px;
}

.EditProfileForm_delete_button__fNcRa:hover {
    background-color: #050101;
    color: white;
}

.EditProfileForm_delete_button__fNcRa:active {
    background-color: #050101;
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/Forms/EditProfileForm/EditProfileForm.module.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,WAAW;IACX,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,iBAAiB;IACjB,qBAAqB;IACrB,mBAAmB;IACnB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,cAAc;IACd,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;IACf,yBAAyB;IACzB,uBAAuB;IACvB,cAAc;IACd,eAAe;IACf,6CAA6C;IAC7C,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB","sourcesContent":[".input {\n    background-color: #eaeaeab3;\n    width: 100%;\n    padding: 10px 15px;\n    border-style: solid;\n    font-size: 15px;\n    font-weight: 500;\n    color: black;\n    border-width: 3px;\n    border-color: #c5c5c5;\n    border-radius: 20px;\n    outline: none;\n    margin-bottom: 10px;\n}\n\n.social_network {\n    margin-top: 20px;\n}\n\n.lock_icon {\n    margin-bottom: 4px;\n    margin-left: 6px;\n    height: 13px;\n    opacity: 80%;\n}\n\n.social_networks_container .input {\n    width: 79%;\n}\n\n.button {\n    margin-top: 15px;\n}\n\n.delete_button {\n    display: none;\n    width: 300px;\n    display: block;\n    font-weight: bold;\n    border-radius: 64px;\n    padding: 10px 35px;\n    font-size: 17px;\n    border: 2px solid #050101;\n    background-color: white;\n    color: #050101;\n    cursor: pointer;\n    transition: background-color 0.2s, color 0.2s;\n    outline: none;\n    margin-top: 20px;\n}\n\n.delete_button:hover {\n    background-color: #050101;\n    color: white;\n}\n\n.delete_button:active {\n    background-color: #050101;\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `EditProfileForm_input__RkHLa`,
	"social_network": `EditProfileForm_social_network__89OXj`,
	"lock_icon": `EditProfileForm_lock_icon__klV7w`,
	"social_networks_container": `EditProfileForm_social_networks_container__kESpA`,
	"button": `EditProfileForm_button__GrcXr`,
	"delete_button": `EditProfileForm_delete_button__fNcRa`
};
export default ___CSS_LOADER_EXPORT___;
